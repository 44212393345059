<template>
  <v-card style="height: 100%; overflow-y: auto;">
    <v-toolbar
      color="white"
      elevation="0"
      class="d-flex align-center"
    >
      <dialog-title :text="title" />

      <v-btn
        text
        class="button__Close mr-0"
        @click="onClickClose"
      >
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </v-toolbar>

    <v-list
      v-if="mount"
      subheader
      three-line
      style="height: 100%; overflow-y: auto; padding-bottom: 100px;"
    >
      <!--
      <v-list-item v-if="!isResign">
        <v-list-item-content>
          <v-textarea
            ref="asContentTextarea"
            :key="activatedId"
            v-model="editData.asContent"
            v-focus-delay:child-textarea="400"
            :label="'처리내용' | translate"
            hide-details
            outlined
            rows="10"
          />
        </v-list-item-content>
      </v-list-item>

-->
      <v-list-item style="padding: 0">
        <v-list-item-content class="">
          <m-form
            ref="form"
            :edit="true"
            :form-data.sync="editData"
            :form-info="formInfo"
            class="overflow-y-auto overflow-x-hidden"
            form-provider="mps"
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!isResign && !mounting">
        <v-list-item-content class="pb-0">
          <v-select-backguard
            ref="doneReasonSelect"
            v-model="editData.doneReasonCode"
            :items="options.doneReasonCodeOptions"
            item-text="codeValue"
            item-value="codeKey"
            :label="'처리유형' | translate"
            hide-details
            outlined
          />
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!mounting">
        <v-list-item-content>
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; font-weight: bold;"
          >
            <span>{{ '서명' | translate }}</span>
            <v-btn
              icon
              @click="clearPad"
            >
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
          </div>
          <div
            ref="signaturePadContainer"
            style="width: 100%; height: 100%; min-height: 400px; max-height: 400px; background-color: rgba(245, 245,245, 0.7)"
          >
            <VueSignaturePad
              ref="signaturePad"
              :option="padOptions"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
      <dialog-apply-button
        :text="'등록' | translate"
        @click:apply="onClickApply"
      />
    </v-list>
  </v-card>
</template>

<script>
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import DialogApplyButton from "@/components/part/sub/DialogApplyButton";
import DialogTitle from "@/components/part/sub/DialogTitle";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
import VSelectBackguard from "@/components/part/VSelectBackguard";
import now from "@/assets/plugins/mps-common/time/now";

export default {
  name: "AsSuccessVuetifyModal",
  components: {DialogApplyButton, VSelectBackguard, DialogTitle},
  mixins: [ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      editData: {
        asContent: null,
        doneReasonCode: null,
        doneDate: now(),
        asContentAttachments: undefined,
        asContentDettachments: undefined,
      },
      padOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      mounting: false,
      mount: false,
      activatedId: 0,

      formInfo: [
        {
          mutable: true,
          title: this.$translate('처리 내용'),
          key: 'asContent',
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.editData.asContentAttachments = v.attachments;
            this.editData.asContentDettachments = v.dettachments;
          },
          options: {
            row: 6
          },
        },
      ]
    }
  },
  computed: {
    title() {
      return this.isResign ? this.$translate("재서명") : this.$translate("완료처리");
    },

    options() {
      return this.propsData.options;
    },

    isResign() {
      return this.propsData.resign;
    },

    item() {
      return this.propsData.item || {};
    },
  },
  created() {
    this.mounting = true;
    setTimeout(() => {
      this.mounting = false;
      this.$nextTick(() => {
        if (this.mount) this.resizePad();
      })
    }, 500);
    this.editData = this.getDefaultState();
  },
  mounted() {
    this.activatedId++;
    this.mounting = true;
    this.editData = this.getState() || this.getDefaultState();
    if (this.item.raw.asContent) this.editData.asContent = this.item.raw.asContent;
    if (this.item.raw.doneReasonCode) this.editData.doneReasonCode = this.item.raw.doneReasonCode;
    setTimeout(() => {
      this.mounting = false;
      this.$nextTick(() => {
        this.clearPad(); // activated 시 마다 pad 를 clear 합니다.
      });
    }, 400);
    this.mount = true;
  },
  methods: {

    /**
     * canvas 의 width, height 속성이 컨테이너(div 태그) 의 width, height style 과 다르면
     * 모바일에서 실제로 그려지는 위치가 달라져 버그가 발생한 것으로 보입니다.
     * 그래서 강제적으로 mounted 시 canvas 의 width, height 속성을 조절해 줍니다(canvas 의 style width, height 는 제대로 작동하지 않습니다)
     */
    resizePad() {
      this.$nextTick(() => {
        const container = this.$refs.signaturePadContainer;
        if (!container) {
          console.error("signaturePadContainer is undefined");
          return;
        }
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        if (!canvas) {
          console.error("canvas is undefined");
          return;
        }
        canvas.setAttribute('width', container.clientWidth);
        canvas.setAttribute('height', container.clientHeight);
      })
    },

    clearPad() {
      this.$refs.signaturePad.clearSignature();
    },

    savePad() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      return isEmpty ? null : data;
    },

    getDefaultState() {
      return {
        doneDate: this.$now(),
        doneReasonCode: null,
        asContent: null,
      }
    },

    onClickClose() {
      this.setState(this.editData); // 닫기전 상태값을 저장한다.
      this.close();
    },

    onClickApply() {
      this.setState(undefined); // 적용을 누르면 상태값을 삭제한다.

      // if (!this.editData.doneDate) return this.$alert(this.$translate("완료일을 입력해주세요"));
      // if (!this.editData.doneReasonCode) return this.$alert(this.$translate("처리유형을 선택해주세요"));

      const serverData = {
        doneDate: this.$parseDate(this.$now()).toISO(),
        doneReasonCode: this.isResign ? this.item.doneReasonCode : this.editData.doneReasonCode,
        asContent: this.isResign ? this.item.asContent : this.editData.asContent,
        signImage: this.savePad(),
        requestFileData: {
          asContentDettachments: this.editData.asContentDettachments,
          asContentAttachments: this.editData.asContentAttachments
        }
      };

      this.callAndClose(serverData);
    },
  }
}
</script>

<style scoped>
>>> .v-toolbar__content, .v-toolbar__extension {
  width: 100%;
  justify-content: space-between;
}

>>> .v-toolbar__items {
  flex: 1;
  justify-content: center;
}

>>> .v-text-field--outlined fieldset {
  border: 1px solid #ddd !important;
}

>>> .v-text-field--outlined {
  border-radius: 0px;
}

</style>
