<template>
  <!--화면출력시(read) 또는 편집시(write) 데이터 변환이 발생하면 V-MODEL 로 연결하여 표시/편집 할 수 없습니다.-->
  <!--value/@change 를 이용해서 데이터를 표시하고 편집합니다.-->
  <!--데이터를 표시할 때 : read 함수를 통해서 변환된 데이터를 value 를 통해서 표시합니다.-->
  <!--데이터를 수정할 때 : write 함수를 통해서 입력된 데이터를 변환해서 formData 에 할당합니다.-->
  <div v-if="formData">
    <template v-for="(info,index) in formInfo">
      <component
        :is="info.component"
        v-if="!!info.component"
        :key="index"
        :props-data="info.propsData"
        :value="info.read ? info.read(sourceModel) : undefined"
        :org-data="sourceModel"
        @update:input="v => info.update ? info.update(sourceModel, v) : undefined"
      />

      <!--디스플래이 컴포넌트입니다. 데이터를 표시만 합니다. (이 컴포넌트는 데이터 편집불가)-->
      <!--V-MODEL 연결 불가. read/write 모드.-->
      <!--v-if : read 함수가 존재하고 편집상태가 아닌경우-->
      <!--key : key 가 지원되면 loader 에서 해당 값을 키로 사용합니다. 아니라면 index 가 키가 됩니다.-->
      <!--label : info 의 title 을 사용합니다.-->
      <!--readonly : 읽기전용 컴포넌트입니다. 항상 읽기전용으로 동작합니다.-->
      <!--value : read 함수로 loader 를 변환 조작해서 표시합니다.-->
      <!--@change : 값이 편집되면 write 함수로 변환 조작해서 loader 로 할당합니다.-->
      <component
        :is="getFormComponent(info.title, info.formReadType)"
        v-else-if="!inEditMode && !!info.read && (!!info.if ? info.if(inEditMode, sourceModel) : true)"
        :key="!!info.key ? info.key : index"
        :params="sourceModel"
        :form-key="!!info.key ? info.key : index"
        :label="info.title"
        :options-data="info.options"
        :readonly="true"
        :in-edit-mode="inEditMode"
        :full-width-label="info.fullWidthLabel"
        :value="!!sourceModel ? info.read(inEditMode, sourceModel) : null"
        :placeholder="info.placeholder"
        :required="info.required"
        :org-data="sourceModel"
        @update:data="v => !!info.updateData ? info.updateData(v) : null"
      />
      <!--편집 컴포넌트입니다.-->
      <!--[readonly] : inEditMode 가 true 인 경우에만 편집할 수 있습니다.-->
      <!--info.mutable 여부가 false 경우 편집할 수 없습니다.-->
      <!--또한 read/write 모드에서 write 함수가 존재하지 않는다면 편집시 올바른 데이터로 변환 할당이 불가능하기 때문에 편집할 수 없습니다.-->
      <component
        :is="getFormComponent(info.title, info.formWriteType)"
        v-else-if="inEditMode && !!info.write && (!!info.if ? info.if(inEditMode, sourceModel) : true)"
        :key="!!info.key ? info.key : index"
        :params="sourceModel"
        :form-key="!!info.key ? info.key : index"
        :disabled="!info.mutable || (!!info.disable && info.disable(inEditMode, sourceModel))"
        :label="info.title"
        :options-data="info.options"
        :readonly="!info.mutable || (!!info.readonly && info.readonly(inEditMode, sourceModel))"
        :in-edit-mode="inEditMode"
        :full-width-label="info.fullWidthLabel"
        :value="!!sourceModel ? (!!info.read ? info.read(inEditMode, sourceModel) : byString(sourceModel, info.key)) : null"
        :placeholder="info.placeholder"
        :required="info.required"
        :org-data="sourceModel"
        @input="newValue => info.write(sourceModel, newValue)"
        @update:data="v => !!info.updateData ? info.updateData(v) : null"
      />
      <!--read/write 함수가 모두 없는 항목은 V-MODEL 로 바로 연결합니다.-->
      <!--V-MODEL 연결 가능-->
      <component
        :is="getFormComponent(info.title, inReadMode ? info.formReadType : info.formWriteType)"
        v-else-if="(!!info.if ? info.if(inEditMode, sourceModel) : true)"
        :key="!!info.key ? info.key : index"
        :params="sourceModel"
        :form-key="!!info.key ? info.key : index"
        :value="!!sourceModel ? (!!info.read ? info.read(inEditMode, sourceModel) : byString(sourceModel, info.key)) : null"
        :disabled="inEditMode && !info.mutable || (!!info.disable && info.disable(inEditMode, sourceModel))"
        :label="info.title"
        :options-data="info.options"
        :readonly="inReadMode || !info.mutable || (!!info.readonly && info.readonly(inEditMode, sourceModel))"
        :in-edit-mode="inEditMode"
        :full-width-label="info.fullWidthLabel"
        :placeholder="info.placeholder"
        :required="info.required"
        :org-data="sourceModel"
        @input="newValue => { assign(sourceModel, info.key, newValue); }"
        @update:data="v => !!info.updateData ? info.updateData(v) : null"
      />
    </template>
  </div>
</template>

<style scoped>

</style>

<script>
    import MFormController from "@/assets/plugins/mps-form/MFormController";
    import MFormProvider from "./form-provider"
    import {FORM_TYPE} from "@/assets/plugins/mps-form/form-types";

    export default {
        name: "DefaultForm",
        mixins: [MFormController],
        props: {
            formProvider: {type: String, default: 'vuetify'},
            /**
             * 폼에 표시할 데이터입니다.
             */
            formData: {type: Object, default: undefined},
            /**
             * 폼을 표시할 데이터입니다.
             */
            formInfo: {type: Array, default: () => []}
        },
        data() {
            return {
                provider: new MFormProvider(this.formProvider),
            }
        },
        computed: {},
        watch: {
            formData(newValue, oldValue) {
                this.sourceModel = newValue;
            }
        },
        created() {
            this.sourceModel = this.formData;
        },
        methods: {
            async validate() {
                if (await this.checkRules()) { // 모든 rules 를 체크후 confirm 단계로 넘어간다.
                    if (await this.checkConfirmRules()) {
                        return true;
                    }
                }
                return false;
            },

            /**
             * confirmRule를 체크하여 실패하면 snacbar 에러 창을 띄어준다.
             * 하나라도 유효성 검증에 실패하면 Promise false 를 반환한다.
             */
            checkRules() {
                return new Promise(async (resolve, reject) => {
                    for (let key in this.formInfo) {
                        try {
                            const info = this.formInfo[key];
                            if (!info.mutable) continue; // mutable 이 false 이면 유효성 검증을 하지 않습니다.
                            if (!!info.if && !info.if(this.inEditMode, this.sourceModel)) continue; // 현재 화면상에 존재하지 않는 컴포넌트이면 유효성 검증을 하지 않습니다.
                            const data = !!info.read ? info.read(this.inEditMode, this.sourceModel) : this.byString(this.sourceModel, info.key);

                            if (info.required) {
                                try {
                                    const result = !!data || info.title + "" + this.$translate("은(는) 필수 입력 항목입니다!");

                                    if (typeof result === 'boolean' && result) {
                                        // 유효성 검증 통과
                                    } else {
                                        this.$snackbarError(result);
                                        resolve(false);
                                        return;
                                    }
                                } catch (e) {
                                    this.$snackbarError(e.message);
                                    resolve(false);
                                    return;
                                }
                            }

                            if (this.$isEmpty(info.rules)) continue;

                            for (let rulesKey in info.rules) {
                                const rule = info.rules[rulesKey];
                                try {
                                    const result = await rule(this.sourceModel, data, info.options);

                                    if (typeof result === 'boolean' && result) {
                                        continue; // 유효성 검증 통과
                                    }

                                    this.$snackbarError(result);
                                    resolve(false);
                                    return;
                                } catch (e) {
                                    this.$snackbarError(e.message);
                                    resolve(false);
                                    return;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                            resolve(false);
                            return;
                        }
                    }
                    resolve(true);
                    return;
                });
            },

            /**
             * confirmRule를 체크하여 실패하면 confirm 확인 창을 띄어준다.
             * 모든 확인 창 클릭시 또는 모든 유효성 검증이 통과되면 Promise true 를 반환한다.
             */
            checkConfirmRules() {
                return new Promise(async (resolve, reject) => {
                    for (let key in this.formInfo) {
                        const info = this.formInfo[key];
                        if (!info.mutable) continue; // mutable 이 false 이면 유효성 검증을 하지 않습니다.
                        if (!!info.if && !info.if(this.inEditMode, this.sourceModel)) continue; // 현재 화면상에 존재하지 않는 컴포넌트이면 유효성 검증을 하지 않습니다.
                        const data = !!info.read ? info.read(this.inEditMode, this.sourceModel) : this.byString(this.sourceModel, info.key);
                        if (this.$isEmpty(info.confirmRules)) continue;
                        for (let rulesKey in info.confirmRules) {
                            const confirmRule = info.confirmRules[rulesKey];
                            try {
                                const result = await confirmRule(this.sourceModel, data, info.options);
                                if (typeof result === 'boolean' && result) {
                                    continue;
                                }

                                this.$dialog()
                                    .message(result)
                                    .buttonNegative(this.$translate('취소'), () => resolve(false))
                                    .buttonPositive(this.$translate('확인'), () => resolve(true))
                                    .build().show();
                                return;
                            } catch (e) {
                                this.$dialog()
                                    .message(e.message)
                                    .buttonNegative(this.$translate('취소'), () => resolve(false))
                                    .buttonPositive(this.$translate('확인'), () => resolve(true))
                                    .build().show();
                                return;
                            }
                        }
                    }

                    return resolve(true);
                });
            },

            getFormComponent(title, type) {
                let found = false;
                Object.keys(FORM_TYPE).forEach(key => {
                    if (type === FORM_TYPE[key]) found = true;
                });
                if (!found) {
                    console.groupCollapsed(`A type '${type}' of '${title}' is unknown type form element.`);
                    console.warn(`You should select a type for the form element. VTextField will be selected by default.`);
                    console.groupEnd();
                    return this.provider.getElement(FORM_TYPE.TEXT_FIELD);
                } else {
                    return this.provider.getElement(type);
                }
            },
        },
    }
</script>
