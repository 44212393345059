<template>
  <div>
    <v-row
      class="mps-form-wrapper"
      :class="{'dense_mode': options.denseMode}"
    >
      <v-col
        :cols="12"
        class="mps-form-label__wrapper mps-form-label__full"
      >
        <span
          v-if="inEditMode && required"
          class="color__red mr-1"
        >*</span>
        <label class="mps-form-label">
          <div
            v-if="!!computedLabel"
            v-html="computedLabel"
          />
          <template v-else>
            {{ label }}
          </template>
        </label>
      </v-col>

      <v-col
        :cols="12"
        class="mps-form-field__wrapper mps-form-field__full"
      >
        <template v-if="inEditMode && !options.resign">
          <div class="in-edit-mode">
            <div
              class="form-textarea__fieldset"
              :class="{ disabled: disabled }"
              :style="styleObject"
            >
              <textarea
                ref="textarea"
                v-model="model"
                class="form-textarea text-left"
                :disabled="disabled"
                :placeholder="placeholder"
                :style="options && options.row ? '' : textareaHeight"
                :rows="options && options.row ? options.row : 1"
                :type="options.type || 'text'"
                :maxlength="options.maxlength || 99999"
              />
            </div>
          </div>
        </template>

        <template v-else-if="!options.resign">
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
              :style="styleObject"
            >
              <textarea
                ref="textarea"
                :placeholder="placeholder"
                class="form-textarea text-left"
                :style="options && options.row ? '' : textareaHeight"
                :rows="options && options.row ? options.row : 1"
                :value="model"
                disabled
                type="text"
              />
            </div>
          </div>
        </template>


        <template v-if="inEditMode">
          <!-- 서버 파일 -->
          <div
            v-if="!$isEmpty(attachments)"
            class="mt-5"
          >
            <div
              v-for="(attach, index) in attachments"
              :key="attach.path"
              class="mb-3"
              style="position: relative;"
            >
              <!-- Image 파일인 경우 미리보기 -->
              <template v-if="isImageFile(attach.serverFile.path)">
                <div
                  v-if="attach.dettached"
                  class="image__overay text-center"
                  style="vertical-align: middle;"
                >
                  <span style="color: #ff0000;">{{ "저장시 파일이 삭제됩니다." | translate }}</span>
                </div>
                <img
                  :src="attach.image"
                  class="ma-auto"
                  :class="{'border-image': options.resign}"
                  :alt="attach.serverFile.originalFilename"
                  style="cursor: pointer; max-width: 100%;"
                >
                <v-btn
                  v-if="!disabled && !options.resign"
                  text
                  class="button__Close button__Delete"
                  style="right: 0px; top: 0px"
                  :class="{'active': attach.dettached}"
                  @click="toggleDettach(attach)"
                >
                  <img src="@/assets/images/main/svg/button_Close.svg">
                </v-btn>
              </template>
              <!-- 이미지가 아닌경우 다운로드 -->
              <template v-else>
                <div class="d-flex">
                  <div style="width: calc(100% - 50px); max-width: calc(100% - 50px);">
                    <v-btn
                      block
                      disabled
                      :class="{'dettached__file': attach.dettached}"
                      tile
                    >
                      <span>{{ attach.serverFile.originalFilename }}</span>
                    </v-btn>
                  </div>
                  <v-btn
                    text
                    class="button__Close button__Delete"
                    style="right: 0px; top: 0px"
                    :class="{'active': attach.dettached}"
                    @click="toggleDettach(attach)"
                  >
                    <img src="@/assets/images/main/svg/button_Close.svg">
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
          <!-- 새로 추가한 파일 -->
          <div
            v-if="$isEmpty(files) !== true"
            class="mt-3"
          >
            <div
              v-for="(file, index) in filesWithId"
              :key="file.id"
              class="mb-3"
              style="border: 2px solid #00ff00; position: relative;"
            >
              <!-- Image 파일인 경우 미리보기 -->
              <template v-if="isImageFile(file.file.name)">
                <img
                  :id="file.id"
                  :alt="file.file.name"
                  src="#"
                  class="ma-auto"
                  style="cursor: pointer; max-width: 100%;"
                >
                <v-btn
                  text
                  class="button__Close button__Delete"
                  style="right: 0px; top: 0px"
                  @click="deleteUploadingFile(file.id)"
                >
                  <img src="@/assets/images/main/svg/button_Close.svg">
                </v-btn>
              </template>
              <template v-else>
                <div class="d-flex">
                  <div style="width: calc(100% - 50px); max-width: calc(100% - 50px);">
                    <v-btn
                      block
                      disabled
                      tile
                    >
                      <span>{{ file.file.name }}</span>
                    </v-btn>
                  </div>
                  <v-btn
                    text
                    class="button__Close button__Delete"
                    style="right: 0px; top: 0px"
                    @click="deleteUploadingFile(file.id)"
                  >
                    <img src="@/assets/images/main/svg/button_Close.svg">
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
          <!-- 파일 추가 버튼 -->
          <div
            v-if="!disabled"
            class="mt-3"
          >
            <template v-if="options.resign" />
            <template v-else>
              <v-btn
                block
                tile
                color="indigo"
                dark
                @click="$refs.files && $refs.files.click()"
              >
                {{ "파일 첨부" | translate }}
              </v-btn>
              <input
                ref="files"
                style="display: none;"
                type="file"
                multiple
                :accept="accept"
                @change="onChangeFiles"
              >
            </template>
          </div>
        </template>
        <!-- View Mode -->
        <template v-else>
          <div
            v-if="!$isEmpty(attachments)"
            class="mt-5"
          >
            <div
              v-for="(attach, index) in attachments"
              :key="attach.path"
              class="mb-3"
            >
              <template v-if="attach.validate">
                <!-- Image 파일인 경우 미리보기 -->
                <template v-if="isImageFile(attach.serverFile.path)">
                  <img
                    v-if="attach.isValidImage"
                    :src="attach.image"
                    :alt="attach.serverFile.originalFilename"
                    class="ma-auto"
                    :class="{'border-image': options.resign}"
                    style="cursor: pointer; max-width: 100%;"
                    @click="show(attach.imageIndex)"
                  >
                </template>
                <template v-else>
                  <v-btn
                    block
                    tile
                    @click="fileDownload(attach)"
                  >
                    <span>{{ attach.serverFile.originalFilename }}</span>
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
              </template>
            </div>
          </div>
          <template v-if="options.resign">
            <!-- 재서명 -->
            <!--            <as-success-dialog-with-modal-->
            <!--              resign-->
            <!--              :item="options.item"-->
            <!--              :options="options"-->
            <!--              :callback="data => options.item.complete(data).catch()"-->
            <!--            >-->
            <!--              <template #default="{ on }">-->
            <!--                <v-btn-->
            <!--                  block-->
            <!--                  tile-->
            <!--                  color="indigo"-->
            <!--                  dark-->
            <!--                  v-on="on"-->
            <!--                >-->
            <!--                  {{ "서명하기" | translate }}-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--            </as-success-dialog-with-modal>-->
          </template>
        </template>
        <image-previewer
          v-if="!options.resign"
          v-model="preview"
          :list="listBase64Image"
          :index="showIndex"
        />
      </v-col>
    </v-row>
  </div>
</template>


<script>
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import MBaseFormFileUpload from "@/assets/plugins/mps-form/providers/MBaseFormFileUpload";
    import {PATH} from "@/constants";
    import ImagePreviewer from "@/components/vuetify-modal/preview/ImagePreviewer";
    import fileManager from "@/assets/plugins/mps-common/file/file-manager";
    import AsSuccessDialogWithModal from "@/components/part/as/button-with-modal/AsSuccessDialogWithModal";

    export default {
        name: "MFormMpsFileUpload",
        components: {ImagePreviewer},
        extends: MBaseFormFileUpload,
        mixins: [TextAreaAutogrow],
        data() {
            return {
                showIndex: 0,
                preview: false,
            };
        },
        computed: {
            listBase64Image() {
                if (!this.attachments) return [];
                return this.attachments.filter(e => fileManager.isImageFile(e.serverFile.path))
                    .map(e => {
                        return {
                            src: e.image,
                            alt: e.serverFile.originalFilename,
                        }
                    });
            },

            styleObject() {
                return {
                    textAlign: this.contentAlign,
                };
            },

            computedLabel() {
              if (typeof this.label === 'function') {
                return this.label(this.orgData)
              } else {
                return undefined
              }
            },
        },
        watch: {
            model() {
                this.$refs.textarea.value = this.model;
                this.autoGrow(this.$refs.textarea);
            },

            inEditMode(newValue) {
                this.$nextTick(() => {
                  this.autoGrow(this.$refs.textarea);
                });
                console.log("inEditMode " + newValue);
            },
        },
        created() {
            window.addEventListener("resize", e => {
                if (this.model) {
                    this.model += " ";
                    this.$nextTick(() => {
                        this.model = this.model.slice(0, -1);
                    });
                }
            });
        },
        mounted() {
            this.autoGrow(this.$refs.textarea);
        },
        methods: {
            fileDownload(attach) {
                try {
                    const url = `${PATH.BASE_URL}${PATH.RESOURCE.FILE}?path=${encodeURI(attach.serverFile.path)}&filename=${encodeURI(attach.serverFile.originalFilename)}`;
                    window.open(url, "_blank");
                } catch (e) {
                    console.error(e);
                    this.$alert("파일 경로가 올바르지 않습니다.");
                }
            },

            isImageFile(path) {
                return this.fileManager.isImageFile(path);
            },

            deleteUploadingFile(id) {
                if (this.$isEmpty(this.files) === true) return;
                let i;
                for (i = 0; i < this.files.length; i++) {
                    if (this.filesWithId[i].id === id) break;
                }

                if (this.files.length === i) return; // 해당하는 파일이 존재하지 않음

                // 존재하면 배열에서 제거
                this.files.splice(i, 1);
            },

            toggleDettach(attach) {
                attach.toggleDettach();
                this.update();
            },

            onChangeFiles() {
                this.filesAppend = this.$refs.files.files;
                this.update();
            },

            /**
             * 부모 컴포넌트에게 데이터를 전달합니다. v-model 값과 별개의 값을 전달하고 싶을 때 사용합니다.
             */
            update() {
                this.updateData = {
                    attachments: this.files,
                    dettachments: !!this.attachments ? this.attachments.filter(e => e.dettached).map(e => e.serverFile).filter(e => !!e) : null,
                };
            },

            show(index) {
                if (this.options.resign) return;
                this.preview = true;
                this.showIndex = index;
            },
        }
    }
</script>

<style scoped>

.dense_mode .mps-form-label__wrapper {
  padding: 9.2px 12px !important;
}

.dense_mode label,
.dense_mode textarea {
  font-size: 12.8px !important;
}

    #app .dettached__file {
        background-color: #ff5252 !important;
        border-color: #ff5252 !important;
        color: white !important;
        text-decoration: line-through !important;
    }

    .button__Delete {
        background: rgba(255, 0, 0, 0.2);
    }

    .button__Delete.active {
        background: rgba(0, 255, 0, 0.2);
    }

    .image__overay {
        background: rgba(255, 0, 0, 0.2);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    >>> .border_bottom {
        border-bottom: 1px solid #ddd !important;
    }

    >>> .border_bottom > div:last-child {
        flex-basis: 50px !important;
        flex-grow: 1 !important;
    }

    .border-image {
        border: 1px solid #ddd;
    }
</style>
