import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";
import {PATH} from "@/constants/index";

const loadCodes = async (codeType) => {
    return await new RequesterBuilder(PATH.OPTIONS.BASE + "/" + codeType.toUpperCase())
        .get()
        .enqueue();
};

const loadRentalcompanyCodes = async (codeType) => {
    return await new RequesterBuilder(PATH.RENTALCOMPANY_CODES.BASE)
        .setObject({codeType: codeType.toUpperCase()})
        .enqueue();
};

const OPTIONS = {
    AS: {
        /**
         * 접수 증상
         */
        REASON_CODE: () => this.loadRentalcompanyCodes("AC"),
        /**
         * 접수 구분
         */
        AS_RECEIPT_TYPE: () => this.loadRentalcompanyCodes("ART"),
        /**
         * AS진행상태
         */
        WORKORDER_STATE: () => this.loadCodes("ST"),
        /**
         * AS기사
         */
        WORKORDER_STAFF: async () => {
            return await this.$request(PATH.USER.EMPLOYEE.LIST)
                .post()
                .enqueue();
        },
    },
};

export default {OPTIONS}