/**
 * 호스트 URL 입니다.
 * @type {string}
 */
const BASE_URL = process.env.VUE_APP_API_HOST;

/**
 * 작업 관련 API 모음입니다.
 */
const WORK_ORDER = {
    // A/S 취소
    CANCEL_WORK: "dashboard/work/as/cancel",
    ASSIGN_WORK: "dashboard/work/as/assign",
    SUMMARY: 'workorder/summary',
    LIST: "workorder/as/list",
    ASSIGN: "workorder/as/assign",
    DETAIL: "workorder/as/detail",
    UPDATE: "workorder/as/edit",
    UPDATE_NEW: "workorder/as/edit-new",
    REGISTER: "workorder/as/register",
    REGISTER_NEW: "workorder/as/register-new",
    PROCESS: "workorder/as/process",
    PROCESS_WITH_SIGN: "workorder/as/process-with-sign",
    PROCESS_WITH_SIGN_NEW: "workorder/as/process-with-sign-new",
    STAFF_STATUS: "workorder/as/status",
    AS_LAST_AS_HISTORY: "workorder/as/lastAsHistory",
    AS_OUTSTANDING_AMOUNT: "workorder/as/outstanding/amount",
    HISTORY: "workorder/history",
    HISTORY_COUNT: "workorder/history-count",
    // A/S 등록과 동시에 완료 처리
    AS_REGISTER_AND_SUCCESS: 'workorder/as/register/auto-success',
}

const NOTICE = {
    GET: "notice",
    LIST: "notice/list",
    ADD: "notice/register",
    DELETE: "notice/delete",
    UPDATE: "notice/update",
}

/**
 * 대시보드에서 사용하는 API 모음입니다.
 */
const DASHBOARD = {
    // 대시보드 당월 매출 개요
    HOME_DASHBOARD_SALES_SUMMARY_THIS_MONTH: "dashboard/sales/summary",
    // 대시보드 매출 개요
    HOME_DASHBOARD_SALES_SUMMARY_THIS_YEAR: "dashboard/sales/homeSummary",
    // 대시보드 매출 개요 - 최근 매출 추이
    HOME_DASHBOARD_SALES_SUMMARY_NEAR_MONTHS: "dashboard/sales/nearmonthlysales",
    // A/S 개요 (접수,진행,완료)
    HOME_DASHBOARD_AS_SUMMARY: "workorder/summary",
    // 거래처별 매출 순위
    HOME_DASHBOARD_SALES_RANK_BY_ACCOUNT: "dashboard/sales/topsalesaccount",
    // 프린터들 상태
    HOME_DASHBOARD_PRINTER_STATE: "dashboard/printer/monitoringsummary",
    // 메인 대시보드 A/S 목록
    HOME_DASHBOARD_AS_LIST: "dashboard/work/dashboard/listfordashboard",
    // 미수금 거래처 TOP5
    HOME_DASHBOARD_OUTSTANDING_BALANCE_TOP5: "dashboard/sales/rank/balancetopaccount",
    // 계약만료 TOP5
    HOME_DASHBOARD_ACCOUNT_CONTRACT_EXPIRATION: "dashboard/home/rank/accountpinterContracttop",
    // A/S TOP5
    HOME_DASHBOARD_AS_BY_ACCOUNT_TOP: "dashboard/work/topasaccount",
    HOME_DASHBOARD_OUTSTANDING_ACCOUNT_COUNT: "dashboard/sales/statistics/balancetopaccount",
    HOME_DASHBOARD_STATISTICS_MOBILE: "dashboard/home/statistics/mobile",
}

const ACCOUNT = {
    GET: "account",
    LIST: "account/list",
    UPDATE: "account/edit",
    REGISTER: "account/register",
    DUPLICATE_BUSINESSNO: "account/check/duplication",
    OFFICIAL_SEAL: "baseinfo/select/seal",
    AUTOMATION: 'account/automation',
    ACCOUNT_INSERT_OR_UPDATE: 'account-new/insert-or-update',
    AUTOMATION_INSERT_OR_UPDATE: 'account/automation/insertAndUpdate',
    BASEINFORMATION: 'account/baseinformation',
}


const CMS = {
    GET_CMS_MEMBERS: "cms/member/list",
    CMS_REQUEST: "cms/request",
    CMS_REQUEST_CANCEL: "cms/request/cancel",
    CMS_REQUEST_ADD: "cms/request/add",
    CMS_REQUEST_ADD_ETC: "payment/pay/etc",
    CMS_REQUEST_LIST: "cms/request/list",
    GET_BANK_CODE: 'codes/bank/code/'
}

const TAX = {
    GET_TAX_MEMBERS: 'account/tax/member/list'
}

const TRANSACTION = {
    GET_TRANSACTION_MEMBERS: 'sales/transaction/member/select'
}

const BILLING = {
    BILLING_DEPOSIT_HIST_LIST: "billing/deposit/list",
    LIST: "billing/list",
}

const MANAGE = {
    /**
     * 임대업체 코드분류
     */
    OPTIONS_LOAD_CODE_CPD: "options/codes/CPD",
    /**
     * 임대업체 코드관리 목록
     */
    OPTIONS_LOAD_CODE_CODE_KEY: "options/codes",
    /**
     * 임대업체 코드관리 목록ManagerCodeListWindow
     */
    OPTIONS_LOAD_CODE_RENTAL_COMPANY_CODES: "options/rentalcompanycodes",
    /**
     * 임대업체 코드 추가
     */
    ADD_RENTAL_COMPANY_CODE: "codes/rentalcompany/add",
    /**
     * 임대업체 코드 수정
     */
    UPDATE_RENTAL_COMPANY_CODE: "codes/rentalcompany/update",
    /**
     * 임대업체 코드 삭제
     */
    DELETE_RENTAL_COMPANY_CODE: "codes/rentalcompany/delete",

    /**
     * 임대업체 직원 목록
     */
    LOAD_MANAGER_STAFF_EMPLOYEES: "staff/employees",

    /**
     * 회원가입 시 아이디 중복체크
     */
    CHECK_DUPILCATE: "auth/check/duplicated",

    /**
     * 임대업체 직원 등록
     */
    ADD_MANAGER_STAFF_EMPLOYEES: "staff/employees/add",

    /**
     * 임대업체 직원 수정
     */
    UPDATE_MANAGER_STAFF_EMPLOYEES: "staff/employees/update",

    /**
     * 임대업체 직원 삭제
     */
    DELETE_MANAGER_STAFF_EMPLOYEES: "staff/employees/delete",


    /**
     * 프린터 메시지 목록
     */
    LOAD_MANAGER_MESSAGE_MESSAGE: "printer/message/rentalcompany/list",

    /**
     * 프린터 메시지 수정
     */
    UPDATE_PRINTER_MESSAGE: "printer/message/rentalcompany/update",

    /**
     * 프린터 메시지 테이블에 추가
     */
    ADD_PRINTER_MESSAGE: "printer/message/rentalcompany/add",

    /**
     * 프린터 메시지 테이블에서 삭제
     */
    DELETE_PRINTER_MESSAGE: "printer/message/rentalcompany/delete",

    /**
     * 회원관리 목록
     */
    LOAD_MEMBERSHIP: "membership/list",

    /**
     * 회원가입 승인
     */
    APPROVE_MEMBERSHIP: "membership/approve",

    /**
     * 공지사항 목록
     */
    LOAD_NOTICE: "notice/list",

    /**
     * 공지사항 추가
     */
    ADD_NOTICE: "notice/register",

    /**
     * 공지사항 수정
     */
    UPDATE_NOTICE: "notice/update",

    /**
     * 공지사항 삭제
     */
    DELETE_NOTICE: "notice/delete",
};

const PRINTER = {
    GET: "printer",
    LIST: "printer/list",
    NEW_LIST: "printer/new/list",
    NEW_COUNT: "printer/new/count",
    NEW_STATICS: "printer/new/statics",
    UPDATE: "printer/edit",
    STATISTICS: "printer/statistics",
    AND_PRINTER_LSIT: "printermodel/and/printer/list",
};

const USER = {
    EMPLOYEE: {
        LIST: "staff/employees"
    }
};

const RENTALCOMPANY_CODES = {
    BASE: "options/rentalcompanycodes",
};

const DEVICE = {
    GET: "device",
    LIST: "device/list",
    UPDATE: "device/edit",
    REGISTER: "device/register",
};

const NATIVE_COMPAT = {
    DELETE_FIREBASE_TOKEN: 'user/fcm/delete',
    REGISTER_FIREBASE_TOKEN: 'user/fcm/reg',
    UPDATE_LOCATION: 'staff/location/update',
};

const OPTIONS = {
    BASE: "options/codes",
};

const CONTRACT = {
    DETAIL: "contract",
    LIST: "contract/list",
    COUNT: "contract/count",
    UPDATE: "contract/edit",
};

const PRINTER_CONTRACT = {
    LIST: "contract/printer/list",
    DETAIL: "printer/contract",
    INSERT_UPDATE: 'contract/printer/insertAndUpdate/list'
};

const BRANCH = {
    LIST: "account/branch/list",
    COUNT: "account/branch/count",
};

const COMMUNITY_USER = {
    REGISTER: "auth/community/user/register",
    LOGIN: "auth/community/login",
};

const MAP_APP = {
    GET_ALL_ACCOUNT: "location/account/list",
    GET_ALL_WORKER: "location/employees/list",
    GET_ALL_WORK: "location/work/list", // 사용안함
    GET_ALL_WORK_NEW: "location/work/new-list",
    GET_ALL_WORKER_WORK: "location/worker/work/list", // 사용안함
    GET_ALL_WORKER_WORK_NEW: "location/worker/work/new-list",
    GET_OUTSTANDING_LIST: "location/outstanding/list",
}

const PUBLIC = {
    WELCOME: {
        LIST_NOTICE: "public/welcome/notice/list",
        DETAIL: "public/welcome/notice/get",
        VERSION_DOWNLOAD: "public/file/download",
        LANGUAGE_TRANSLATE: "public/language",
        DOWNLOAD_FILE: "public/welcome/notice/file/download",
        TIMEZONE: "public/timezone"
    },
    ADDRESS_KOREA: "public/address/korea",
    PRINTER_All_CONTRACT: 'public/printer/all/contract',
    MONITORING_PRINTER_All_CONTRACT: 'public/monitoring/count',
    SERVER_VERSION: 'public/server-version',
};

const RESOURCE = {
    IMAGE_BASE64: "resource/imageBase64",
    FILE: "resource/file",
}
;

const REPORT = {
    /** 업무일지 & 기간별 A/S 요약 API */
    WORK: {
        BY_TYPE: "report/as/type",
        BY_TYPE_ACCOUNT: "report/as/type/account",
        BY_WORKER: "report/worker/count",
        BY_MONTHLY: "report/year/monthly",
        BY_WEEKLY: "report/as/week",
        BY_WORK_MONTHLY: "report/work/monthly",
    },
    /** 일계표 API */
    STATUS: {
        COMPANY: "report/company/status",
        AS: "report/as/status",
        CMS: "report/cms/status",
        TAX: "report/tax/status",
        SALES: "report/sales/status",
    },
    WIDGET: {
        SALES: "report/widget/sales",
        ACCOUNT: "report/widget/account",
        CONTRACT: "report/widget/contract",
        WORK: "report/widget/work",
        AUTO: "report/widget/automation",
        SETTING_SELECTED : "report/setting/select",
        SETTING_UPDATE : "report/setting/insertOrUpdate",
        COMMENT_UPDATE: "report/comment/insertOrUpdate"
    },
    WORK_REPORT: {
        LIST: 'work/report/list',
        LIST_COUNT: 'work/report/list/count',
        UPDATE: 'work/report/update',
    },
};

const BOARD = {
    NOTICE: {
        LIST: 'board/notice/list',
        DISPLAY: 'board/notice/display',
    },
}

const ITEM = {
    LIST: 'item/list',
    GROUP: 'item/group/list'
}

const TERMS_AND_CONDITIONS = {
    INSERT: 'terms-and-conditions/insert',
    SELECT: 'terms-and-conditions/select',
}

const RENTAL_COMPANY = {
    INFO: 'rentalcompany/info'
}

const ELECTRICAL = {
    CONTRACT_INSERT: 'electrical/contract/insert',
    CONTRACT_SEND_MAIL: 'electrical/contract/send-email',
    CONTRACT_SELECT_BY_TOKEN: 'electrical/contract/select-by-token',
    CONTRACT_SELECT_BY_ID: 'electrical/contract/select-by-id',
    CONTRACT_SELECT_BY_CONTRACT: 'electrical/contract/select-by-contract',
    CMS_TEMP_INSERT: 'electrical/cms/temp/insert-or-update',
    CMS_TEMP_SELECT: 'electrical/cms/temp/select',
    CMS_TEMP_SEND_MAIL: 'electrical/cms/temp/send-email'
}

const TONER_REFILL = {
    RETRIEVE: 'toner-refill/retrieve',
    RETRIEVE_HISTORY: 'toner-refill/retrieve/history',
    RETRIEVE_LIST: 'toner-refill/retrieve/list',
    RETRIEVE_LIST_SUMMARY: 'toner-refill/retrieve/list/summary',
    INSERT_OR_UPDATE: 'toner-refill/insert-or-update',
}

const CONSULT_NOTE = {
    ACCOUNT: 'account/log/list',
    LIST: 'account/log/list/rentalcompany',
    DETAIL: 'account/log',
    REGISTER: 'account/log/register',
    UPDATE: 'account/log/update',
}

const EXTERNAL ={
    RETRIEVE_QR_ASSET: 'external/retrieve/qr/asset',
    REGISTER_NEW_AS: 'external/as/register-new',
    RETRIEVE_QR_ASSET_AS_LIST: 'external/retrieve/qr/asset/as',
    RETRIEVE_QR_ASSET_AS_DETAIL: 'external/retrieve/qr/asset/as/detail',
    RETRIEVE_QR_ASSET_AS_PROCESS: 'external/retrieve/qr/asset/as/process',
}

export default {
    RESOURCE,
    MAP_APP,
    BASE_URL,
    DASHBOARD,
    WORK_ORDER,
    ACCOUNT,
    CMS,
    TAX,
    TRANSACTION,
    MANAGE,
    NOTICE,
    BILLING,
    PRINTER,
    USER,
    RENTALCOMPANY_CODES,
    DEVICE,
    NATIVE_COMPAT,
    OPTIONS,
    CONTRACT,
    PRINTER_CONTRACT,
    BRANCH,
    COMMUNITY_USER,
    PUBLIC,
    REPORT,
    BOARD,
    ITEM,
    TERMS_AND_CONDITIONS,
    RENTAL_COMPANY,
    ELECTRICAL,
    TONER_REFILL,
    CONSULT_NOTE,
    EXTERNAL,
    SEARCH_ADDRESS: "address",
    USER_LOGIN: "auth/login",
    USER_RETIRED: "auth/retired",
    USER_REGISTER: "auth/user/register",
    AS_HISTORY_LIST: "workorder/dashboard/listByAccount",
    AS_STAFF_LIST: "dashboard/staff/list",
    AS_DETAIL: "workorder/dashboard/detail",
    AS_DELETE: "workorder/as/remove",

    ACCOUNT_REGISTER: "account/register",
    ACCOUNT_DELETE: "account/remove",
    ACCOUNT_LIST: "account/list",
    ACCOUNT_UPDATE: "account/edit",
    ACCOUNT_BRANCH: "account/branch",
    ACCOUNT_BRANCH_LIST: "account/branch/list",
    ACCOUNT_BRANCH_UPDATE: "account/branch/edit",
    ACCOUNT_BRANCH_DELETE: "account/branch/remove",
    ACCOUNT_BRANCH_REGISTER: "account/branch/register",
    BILLING_LIST: "billing/list",

    CMS_UPDATE: "cms/edit",
    CMS_CHANGE_HISTORY_LIST: "cms/history/change/list",
    TAX_INVOICE: "tax/invoice",
    TAX_INVOICE_UPDATE: "tax/invoice/edit",
    TAX_INVOICE_REGISTER: "tax/invoice/register",
    TAX_INVOICE_ISSUE_LIST: "tax/invoice/issue/list",
    CONTRACT_LIST: "contract/list",
    CONTRACT_UPDATE: "contract/edit",
    CONTRACT_PRINTER_LIST: "contract/printer/list",
    ADD_CONTRACT: "contract/register",
    CONTRACT_DELETE: "contract/remove",
    ADD_PRINTER_CONTRACT: "contract/printer/register",
    DELETE_PRINTER_CONTRACT: "contract/printer/delete",
    EDIT_PRINTER_CONTRACT: "contract/printer/edit",
    PRINTER_LIST: "printer/list",

    PRINTER_MONITORING_LIST: "printer/monitoring/list",
    ACCOUNT_AS_LIST: "workorder/dashboard/listByAccount",
    ACCOUNT_AS_STAFF_REGISTER: "workorder/staff/register",
    PRINTER_MESSAGE: "printer/message",
    PRINTER_MESSAGE_LIST: "printer/message/list",
    PRINTER_USAGE_MONTHLY_LIST: "printer/usage/monthly/list",
    PRINTER_USAGE_MONTHLY_LIST_ON_ACCOUNT: "printer/account/usage/monthly/list",
    PRINTER_USAGE_HISTORY_LIST: "printer/usage/history/list",
    PRINTER_USAGE_LATEST: "printer/usage/history/latest",
    PRINTER_USAGE_HISTORY_REGISTER: "printer/usage/history/register",
    PRINTER_CALCULATION_DATE: 'printer/usage/calculation-startdate',
    PRINTER_USAGE_BY_PERIOD_ON_ACCOUNT: 'printer/usage/history/summary',
    DEVICE_LIST: "device/list",
    DEVICE_ADDABLE_LIST: "device/addable/list",
    DEVICE_ADD: "device/add/account",
    DEVICE_INSTALL: "device/install",
    DEVICE_UPDATE: "device/edit",
    OPTIONS_LOAD_EMPLOYEE: "options/user/employee",
    OPTIONS_LOAD_RENTAL_CODE: "options/rentalcompanycodes/AC",
    OPTIONS_LOAD_RENTAL_CODE_PRODUCT: "options/rentalcompanycodes/TXMDL",
    OPTIONS_LOAD_CODE_ST: "options/codes/ST",
    OPTIONS_LOAD_CODE: "options/codes/ART",

    OPTIONS_LOAD_CODE_CPD: "options/codes/CPD",      //코드분류
    OPTIONS_LOAD_CODE_CODE_KEY: "options/codes",     //코드관리 목록
    OPTIONS_LOAD_CODE_RENTAL_COMPANY_CODES: "options/rentalcompanycodes",     //코드관리 목록
    OPTIONS_LOAD_CODE_EMPLOYEE: "options/Employee",  //직원관리

    PRINTER_MODEL_LIST: "printermodel/list",

    STAFF_EMPLOYEES_LIST: "staff/employees",


    // --------------------------------------------------------

    SUPPORTED_PRINTER_LIST: "monitoringPrinter/supportedPrinterList",
    SUPPORTED_PRINTER_ADD: "monitoringPrinter/supportedPrinterAdd",
    SUPPORTED_PRINTER_UPDATE: "monitoringPrinter/supportedPrinterUpdate",
    SUPPORTED_PRINTER_DELETE: "monitoringPrinter/supportedPrinterDelete",

    PRINTER_RULE_LIST: "monitoringPrinter/prtInfoRuleList",
    PRINTER_RULE_ADD: "monitoringPrinter/prtInfoRuleAdd",
    PRINTER_RULE_UPDATE: "monitoringPrinter/prtInfoRuleUpdate",
    PRINTER_RULE_DELETE: "monitoringPrinter/prtInfoRuleDelete",

    VERSION_DIST_CONTRY_LIST: "agentVersion/listBy/country",
    VERSION_DIST_COMPANY_LIST: "agentVersion/listBy/rentalcompany",

    DEVICE_VERSION_UPDATE_COUNTRY: "agentVersion/updateForCountry",
    DEVICE_VERSION_UPDATE_RENTALCOMPANY: "agentVersion/update/rentalcompany/distribution",
    DEVICE_VERSION_LIST: "agentVersion/list",
    DEVICE_VERSION_ADD: "agentVersion/add",
    DEVICE_VERSION_DEL: "agentVersion/delete",

    AGENT_INTERVAL_LIST: "monitoringPrinter/agentInterval/list", //수집 주기 관리 목록
    AGENT_INTERVAL_INSERT: "monitoringPrinter/agentInterval/insert", //수집 주기 관리 추가
    AGENT_INTERVAL_DELETE: "monitoringPrinter/agentIntervalCase/del", //수집 주기 관리 삭제
    AGENT_INTERVAL_UPDATE: "monitoringPrinter/agentInterval/update", //수집 주기 관리 수정


    AGENT_INTERVAL_COMPANY_UPDATE: "monitoringPrinter/agentIntervalCase/update", //설정주기 업데이트 임대업체
    AGENT_INTERVAL_COUNTRY_UPDATE: "monitoringPrinter/agentIntervalCaseByCountry/update",
    AGENT_INTERVAL_RENTAL_COMPANY: "monitoringPrinter/agentIntervalCase/listBy/rentalcompany", // 수집 주기 설정 임대업체별 LIST
    AGENT_INTERVAL_COUNTRY: "monitoringPrinter/agentIntervalCase/listBy/country", // 수집 주기 설정 국가별 LIST

    USER_FIND_ID: "auth/loginId/Find", //회원아이디 찾기
    USER_FIND_PASSWORD: "auth/send/password", //비밀번호 이메일 전송
    // FIND_USERID: "pub/findUserid",
    // FIND_USERPW: "pub/findUserpw",
    // PRT_LIST: "product/printer/list",
    // PRT_LISTM: "product/printer/list_m",
    // PRT_USE_LIST: "product/printer/meterday/list",
    // DEVICE_LISTM: "product/device/list_m",
    // DEVICE_ADD: "product/device/add",
    // DEVICE_UPT: "product/device/upt",
    // DEVICE_DEL: "product/device/del",
    // HOME_DASHBOARD: "company/home/dashboard",
    // HOME_ASSTAFF: "company/home/report/as_staff",
    AS_LIST: "workorder/dashboard/list",
    AS_UPDATE: "workorder/dashboard/edit",
    // AS_ASSIGN: "company/dashboard/assign",
    // AS_FINISH: "company/dashboard/finish",
    // AS_CANCEL: "company/dashboard/cancel",
    // AS_GETVAL: "company/dashboard/get_val",
    // AS_UPT: "company/dashboard/upt",
    // AS_ADD: "company/dashboard/add",
    // STAFF_LIST: "company/staff/list",
    // STAFF_ADD: "company/staff/add",
    // STAFF_UPT: "company/staff/upt",
    // STAFF_DEL: "company/staff/del",
    // NOTI_LIST: "company/staff/noti/list",
    // NOTI_LISTU: "company/staff/noti/listu",
    // NOTICE_LIST: "support/notice/list",
    // CS_LISTM: "account/customer/list_m",
    // CS_UPTM: "account/customer/upt_m",
    // CS_REGM: "account/customer/reg_m",
    // CS_BRANCH_LIST: "account/customer/branch/list",
    // CSSTAFF_LISTALL: "account/cs_staff/list_all",
    // SALE_BILLINGLIST: "company/sale/billing/list",
    // SALE_ACCTLIST: "company/sale/acct/list"

    PRINTER_INSTALL_LOG_REG: "printer/install/log/insert",
    PRINTER_INSTALL_LOG_LIST: "printer/install/log/list",
    PRINTER_INSTALL_LOG_UPD: "printer/install/log/update",

    // 인증 토큰 재발급 요청
    REFRESH_ACCESS_TOKEN: 'auth/refresh/token',
};
