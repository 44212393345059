<template>
  <div>
    <v-row
      class="mps-form-wrapper"
      :class="{'dense_mode': options.denseMode}"
    >
      <v-col
        cols="12"
        class="mps-form-label__wrapper mps-form-label__full"
        style="display: flex;"
      >
        <label
          class="mps-form-label"
        >{{ label }}</label>
        <!--        <a-->
        <!--          v-if="canShowMap"-->
        <!--          :href="canShowMap ? 'geo:' + lat+','+lng+'('+display+')' : 'javascript:void(0)'"-->
        <!--          class="ml-1"-->
        <!--        >-->
        <a
          v-if="canShowMap"
          :href="canShowMap ? 'geo:' + lat+','+lng : 'javascript:void(0)'"
          class="ml-1"
        >
          <img
            v-if="canShowMap"
            style="width: 18px;"
            src="@/assets/images/main/svg/icon_map.svg"
          >
          <img
            v-else
            src="@/assets/images/main/svg/icon_map_gray.svg"
          >
        </a>
      </v-col>
      <v-col
        cols="12"
        class="mps-form-field__wrapper mps-form-field__full"
      >
        <template v-if="inEditMode">
          <div class="in-edit-mode">
            <div
              class="form-textarea__fieldset"
              :class="{ disabled: disabled }"
            >
              <textarea
                ref="textarea"
                class="form-textarea text-left"
                :value="display"
                :disabled="disabled"
                readonly
                :placeholder="placeholder"
                :rows="options.denseMode ? 2 : 1"
                type="text"
                :style="options.denseMode ? undefined : textareaHeight"
                @click="onClick"
              />
            </div>
          </div>
          <form-error
            :rules="rules"
            :model="() => { options.accountId }"
            :check-error="checkError"
            :error-message="errorMessage"
          />
        </template>
        <template v-else>
          <div class="in-view-mode">
            <div
              class="form-textarea__fieldset"
            >
              <textarea
                ref="textarea"
                class="form-textarea text-left pa-0"
                :value="display"
                :placeholder="placeholder"
                disabled
                :rows="options.denseMode ? 2 : 1"
                :style="options.denseMode ? undefined : textareaHeight"
                type="text"
              />
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
    import MFormAddressPicker from "@/assets/plugins/mps-form/providers/MFormAddressPicker";
    import TextAreaAutogrow from "@/assets/plugins/mps-form/providers/mixins/TextAreaAutogrow";
    import FormError from "@/assets/plugins/mps-form/providers/components/error/FormError";
    import ModalVuetifyUser from "@/assets/plugins/vuetify-modal/ModalVuetifyUser";
    import {MODAL_VUETIFY_NAMES} from "@/constants/vuetify-modal";
    import {ETC} from "@/constants/etc-code";

    export default {
        name: "MFormMpsAddressPicker",
        components: {FormError},
        extends: MFormAddressPicker,
        mixins: [TextAreaAutogrow, ModalVuetifyUser],
        data() {
            return {}
        },
        computed: {
            lat() {
                return this.options.lat;
            },

            lng() {
                return this.options.lng;
            },

            canShowMap() {
                if (this.$isNOU(this.lat) || this.$isNOU(this.lng)) return false;
                if (isNaN(this.lat) || isNaN(this.lng)) return false;
                if (this.lat === 0 && this.lng === 0) return false;
                return true;
            },
        },
        watch: {
            display() {
                this.$refs.textarea.value = this.display;
                this.autoGrow(this.$refs.textarea);
            }
        },
        created() {
            window.addEventListener("resize", e => {
                if (this.display && this.display.trim()) {
                    this.resizeSpace = " ";
                    this.$nextTick(() => {
                        this.resizeSpace = "";
                    });
                }
            });
        },
        mounted() {
            this.autoGrow(this.$refs.textarea);
        },
        methods: {
            onClick() {
                this.openModalVuetify(MODAL_VUETIFY_NAMES.FORM.PICKER_ADDRESS, {
                        fullscreen: true,
                        transition: ETC.DIALOG.TRANSITION.BOTTOM_TRANSITION,
                    }, {title: this.titleComputed,},
                    (data) => {
                        this.model = data;
                    }
                );
            },
        },
    }
</script>
<style scoped>

.dense_mode .mps-form-label__wrapper {
  padding: 9.2px 12px !important;
}

.dense_mode label,
.dense_mode textarea {
  font-size: 12.8px !important;
}
</style>
