<script>
export default {
  name: "MobileBaseInput",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    subLabel: {
      type: String,
      default: undefined,
    },
    unit: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredBlue: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: undefined,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: undefined,
      descriptionOn: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.internalValue = newValue
      }
    },
    internalValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    showHideDescription() {
      this.descriptionOn = !this.descriptionOn
    },
  },
}
</script>
